import React from "react";
import { Link } from "gatsby";
import {ArrowRightOutlined} from '@ant-design/icons';
import {Card} from 'antd';

import speedingImg from '../../images/services/Speeding Tickets.png';
import ServiceTemplateCascade from "./ServiceTemplateCascade";
import Routes from "../../constant/routes";


const Items = [
  {key: 1, title: 'How much does a speeding ticket cost in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">How much does a speeding ticket cost in Missouri?If you just pay the ticket, you are pleading guilty to the violation. In Missouri, the fine range is between $50 and $150, plus court costs of between $25-$75. BUT you will also have to pay hundreds, even thousands of dollars, for insurance each year for 3-5 years. <strong>TicketTamer can fix your ticket for just $75 for tickets for 10-14 miles over the speed limit --and you won’t have to go to court. </strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you just pay the ticket, you are pleading guilty to the violation. In Missouri, the fine range is between $50 and $150, but could be more depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court.
    {/* <br/><br/>
    <a href="https://www.courts.mo.gov/page.jsp?id=310" className="Link" target="_blank" rel="noreferrer">Click here for state and local court fine information. <ArrowRightOutlined /></a> */}
    <br/><br/>
    You will also have to pay court costs, which range from $25 to $75, plus hundreds, even thousands more for your insurance – for the next 3-5 years --because insurers will use the ticket to increase your rates.
    {/* <br/><br/>
    <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer"> Click here for more information on tickets and insurance. <ArrowRightOutlined /></a> */}
    <br/><br/>
    You’ll also put your license at risk of suspension or even revocation if you already have tickets on your record or get any more.
    {/* <br/><br/>
    <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more on license suspension and revocation. <ArrowRightOutlined /></a> */}
    <br/><br/>
    <i><strong>This is the most expensive way to handle your ticket.</strong></i>
    <br/><br/>
    <strong>TicketTamer can keep your insurance rates down and your record clean by fixing your ticket. We will negotiate with the prosecutor to reduce the ticket to a non-moving or no point violation so your record will stay clean and your insurance rates won’t go up.</strong>
    <br/><br/>
    TicketTamer charges just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.
    </div>
    
  </div>},
  {key: 2, title: 'What you absolutely need to know before you pay your speeding ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">There are five things you need to know before you just pay your speeding ticket. Compare and see why 40 percent of TicketTamer cases are referrals or satisfied returning clients.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    There are five things you need to know before you just pay your speeding ticket. Compare and see why 40 percent of TicketTamer cases are referrals or satisfied returning clients.
    <br/>
    <br/>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
      You’ll be pleading guilty to the violation, and it will be on your record forever.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    You’ll get at least 2-4 points on your driving record in point system states, including Missouri. Your insurance company will use these points to increase your rates hundreds, even thousands, of dollars for the next 3-5 years. This is why just paying the ticket is the most expensive way to handle your ticket.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    If you get too many points, your license will be suspended or even revoked. In Missouri, your license will be suspended for up to 90 days if you have 8 points in 18 months. It will be revoked if you have 12 points in 12 months or 18 points in 24 months.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    The points will drop off your record after 3 years, but the violation will stay on forever and insurance companies can use this to raise your rates or even deny you coverage.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    You can avoid all of this by hiring TicketTamer to fix your ticket. We will get the charge reduced down to a non-moving or no point violation, which will keep your record clean and insurance rates down.
    </Card>
    </div>
    <div className="BodyGreyLeft mb16">
    TicketTamer charges just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit – and you won’t have to go to court. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.

    </div> 
  </div>},
  {key: 3, title: 'How many points will I have on my license if I plead guilty and pay my court fine for a speeding ticket without hiring a speeding ticket lawyer?', preview: <div>
    <div className="BodyGreyLeft mb16">How many points will I have on my license if I plead guilty and pay my court fine for a speeding ticket without hiring a speeding ticket lawyer?If you just pay the ticket, you’ll get at least 2-4 points on your driving record in point system states like Missouri. Your insurance company will use these points to increase your rates hundreds, even thousands, of dollars for the next 3-5 years. TicketTamer prevent this from happening for just $75 for tickets for 10-14 miles over the speed limit -- and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you just pay the ticket, you’ll get at least 2-4 points on your driving record in point system states like Missouri. Your insurance company will use these points to increase your rates hundreds, even thousands, of dollars for the next 3-5 years.
    <br/><br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri's point system <ArrowRightOutlined /></a>
    <br/><br/> */}
    <i><strong>This is the most expensive way to handle your ticket.</strong></i>
    <br/><br/>
    And by the way, those points will also put your license at risk of suspension or even revocation. In Missouri, your license will be suspended for up to 90 days if you have 8 points in 18 months. It will be revoked if you have 12 points in 12 months or 18 points in 24 months.
    <br/><br/>
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more on license suspension and revocation. <ArrowRightOutlined /></a>
    <br/><br/> */}
    You can avoid all of this by hiring TicketTamer to fix your ticket. We will get the charge reduced down to a non-moving or no point violation, which will keep your record clean and insurance rates down.
    <br/><br/>
    TicketTamer charges just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.You'll love our service. We guarantee it.
    </div>
    
  </div>},
  {key: 4, title: 'Why should I hire a speeding ticket lawyer to fix my ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">Why should I hire a speeding ticket lawyer to fix my ticket?If you just pay your ticket, you will get 2-4 points on your record and your insurance rates will go up hundreds, even thousands of dollars, each year for3-5 years TicketTamer prevent this from happening for just $75 for tickets for 10-14 miles over the speed limit -- and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Just paying your speeding ticket is the worst thing you can do. It will put 2-4 points on your record for 3 years and result in an insurance increase of hundreds, even thousands, of dollars a year for 3-5 years, depending upon the state and your insurer!
    {/* <br/><br/>
    <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer"> Click here for more information on tickets and insurance. <ArrowRightOutlined /></a> */}
    <br/><br/>
    It will also put your license at risk of suspension or revocation. In Missouri, your license will be suspended for up to 90 days if you have 8 points in 18 months. It will be revoked if you have 12 points in 12 months or 18 points in 24 months.
    {/* <br/><br/>
    <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri's point system. <ArrowRightOutlined /></a> */}
    <br/><br/>
    You can avoid all of this by hiring a lawyer to fix your ticket – and the lawyers at TicketTamer are the best. Our success rate is 97%, but we are more proud that 40% of our cases are referrals or satisfied returning clients. We know the law and the courts, and will get the charge reduced down to a non-moving or no point violation.
    </div>
    
  </div>},
  {key: 5, title: 'What questions should you ask before you hire an attorney to fix your speeding ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">Before hiring an attorney to fix a speeding ticket, you should ask five questions before you hire a speeding ticket lawyer. Compare and see why 40 percent of TicketTamer cases are referrals or satisfied returning clients.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Before hiring an attorney to fix a speeding ticket, you should ask these five questions:
    <br/>
    <br/>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>Have you fixed traffic tickets in this court before?</strong>
    <br/>
    If the answer is yes, that’s good, of course. If the answer is no, consider getting another lawyer who has worked in that court before. It may not make a difference, but then again it may and you don’t want to take any chances.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>How much will it cost to fix my ticket?</strong>
    <br/>
    The cost for legal services to fix a ticket can vary on a number of factors, such as what the ticket is for and what court the ticket is in. A good lawyer should be able to give you an accurate quote quickly if you provide this information. If they don’t, move on.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>Will the fee include court fine and court costs?</strong>
    <br/>
    Some lawyers include the fine and court costs within their fees, some do not. The answer to this question may explain why you are getting very different quotes.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>How will you keep me informed of developments on my case?</strong>
    <br/>
    Good lawyers take special care to keep you updated on your case. Bad lawyers will leave you wondering what will go on, and will probably try to avoid this question by simply reassuring you that they will keep in touch with them. Ask the lawyer how he or she will do that?
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>What kind of support will I have if I have to pay a fine?</strong>
    <br/>
    If the lawyer fee includes your fine, you won’t need to worry about this question. If not, be sure to ask if they will help you make you get your fine paid correctly. A good lawyer will provide detailed instructions for payment and may be willing to pay it for you for a fee
    </Card>

    </div>
  </div>},
  {key: 6, title: 'How much does a speeding ticket lawyer cost in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">The cost of a speeding ticket lawyer in Missouri will vary depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court. TicketTamer charges just $75 for a basic speeding ticket up to 14 miles over the speed limit – and only $45 for tickets between 1-9 miles over the speed limit – and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    The cost of a speeding ticket lawyer in Missouri will vary depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court. TicketTamer charges just $75 for a basic speeding ticket up to 14 miles over the speed limit – and only $45 for tickets between 1-9 miles over the speed limit. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court, and other factors.
    <br/><br/>
    Click here for the five questions you should ask before hiring a lawyer to fix your speeding ticket.
    </div> 
  </div>},
  {key: 7, title: 'How much will my insurance rates increase if I plead guilty to speeding and pay my court fine?', preview: <div>
    <div className="BodyGreyLeft mb16">It depends on your situation, but your insurance rates will go up hundreds, even thousands, of dollars each year for 3-5 years if you just pay the fine. <strong>TicketTamer can prevent this by fixing your ticket for just $75 for a basic speeding ticket up to 14 miles over the speed limit – and only $45 for tickets between 1-9 miles over the speed limit – and you won’t have to go to court.</strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    It depends on your situation, including where you live, how many points you already have, and whether you have younger drivers on your policy.
    {/* <br/><br/>
    <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more information on insurance and tickets. <ArrowRightOutlined /></a> */}
    <br/><br/>
    Here is what we can say for certain: When you get a ticket, you will lose any good driver discount you may have, and be slapped with a surcharge for the ticket. The amount will vary, but in our experience, just one ticket will increase your rates hundreds, even thousands of dollars a year – for 3-5 years.
    <br/><br/>
    <strong>TicketTamer can help you avoid this by negotiating with the prosecutor to reduce your ticket down to a non-moving or no-point violation. Our success rate is 97%, but we are more proud that 40% of our cases are referrals or satisfied returning clients. We know the law and the courts, and will get the charge reduced down to a non-moving or no point violation.</strong>
    
    </div> 
  </div>},
  {key: 8, title: 'How much does a 10 mph over speeding limit ticket cost?', preview: <div>
    <div className="BodyGreyLeft mb16">If you just pay the ticket, the fine will likely be between $50-$100plus court cost between $25-$75. TicketTamer charges just $75 for a basic speeding ticket up to 14 miles over the speed limit – and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you just pay the ticket, the fine will likely be between $50-$100plus court cost between $25-$75.
    <br/><br/>
    {/* <a href="https://www.courts.mo.gov/page.jsp?id=310" className="Link" target="_blank" rel="noreferrer">Click here for state and local court fine information. <ArrowRightOutlined /></a>
    <br/><br/> */}
    But you will also have to pay hundreds, even thousands, more for your insurance because that goes up when you get a ticket. The actual cost will vary depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court.
    {/* <br/><br/>
    <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer"> Click here for more information on tickets and insurance. <ArrowRightOutlined /></a> */}
    <br/><br/>
    <i><strong>This is the most expensive way to handle your ticket.</strong></i>
    <br/><br/>
    <strong>TicketTamer can keep your insurance rates down by fixing your ticket. We will negotiate with the prosecutor to reduce the ticket to a non-moving or no point violation so your record will stay clean and your insurance rates won’t go up.</strong>
    <br/><br/>
    TicketTamer charges just $75 for a basic speeding ticket of up to 14 miles per hour over the speed limit, although. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.
    </div> 
  </div>},
  {key: 9, title: 'How much does a 15 mph over speeding limit ticket cost?', preview: <div>
    <div className="BodyGreyLeft mb16">If you just pay the ticket, the fine will likely be between $50-$100plus court cost between $25-$75. But your insurance will also go up hundreds, even thousands, of dollars each year for 3-5 years because of the points you will get on your record. TicketTamer charges just $150 for a speeding ticket 15-19 miles over the speed limit – and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you just pay the ticket, the fine will likely be between $50-$100 plus court cost between $25-$75.
    <br/><br/>
    {/* <a href="https://www.courts.mo.gov/page.jsp?id=310" className="Link" target="_blank" rel="noreferrer">Click here for state and local court fine information. <ArrowRightOutlined /></a>
    <br/><br/> */}
    But you will also have to pay hundreds, even thousands, more for your insurance because that goes up when you get a ticket. The actual cost will vary depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court.
    <br/><br/>
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer"> Click here for more information on tickets and insurance. <ArrowRightOutlined /></a>
    <br/><br/> */}
    This is the most expensive way to handle your ticket.
    <br/><br/>
    <strong>TicketTamer can keep your insurance rates down by fixing your ticket. We will negotiate with the prosecutor to reduce the ticket to a non-moving or no point violation so your record will stay clean and your insurance rates won’t go up.</strong>
    <br/><br/>
    TicketTamer charges just $150 for a basic speeding ticket of up to 15-19 miles per hour over the speed limit, the $75 basic ticket fee we charge for all tickets, and a $75 surcharge for the higher speed. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.
    </div> 
  </div>},
  {key: 10, title: 'How much does a 20 mph over speeding limit ticket cost?', preview: <div>
    <div className="BodyGreyLeft mb16">If you just pay the ticket, the fine will likely be at least $150, plus court cost between $25-$75. But your insurance will also go up hundreds, even thousands, of dollars each year for 3-5 years because of the points you will get on your record. TicketTamer charges just $275 for a speeding ticket 20-24 miles over the speed limit – and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you just pay the ticket, the fine will likely be at least $150, plus court cost between $25-$75.
    {/* <br/><br/>
    <a href="https://www.courts.mo.gov/page.jsp?id=310" className="Link" target="_blank" rel="noreferrer">Click here for state and local court fine information. <ArrowRightOutlined /></a> */}
    <br/><br/>
    But you will also have to pay hundreds, even thousands, more for your insurance because that goes up when you get a ticket. The actual cost will vary depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court.
    {/* <br/><br/>
    <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer"> Click here for more information on tickets and insurance. <ArrowRightOutlined /></a> */}
    <br/><br/>
    <i><strong>Just paying your ticket is the most expensive way to handle your ticket.</strong></i>
    <br/><br/>
    <strong>TicketTamer can keep your insurance rates down and your record clean by fixing your ticket. We will negotiate with the prosecutor to reduce the ticket to a non-moving or no point violation so your record will stay clean and your insurance rates won’t go up.</strong>
    <br/><br/>
    TicketTamer charges just $275 for a speeding ticket of up to 20-24 miles per hour over the speed limit: the $75 basic ticket fee we charge for all tickets, and a $200 surcharge for the higher speed. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.
    </div> 
  </div>},
  {key: 11, title: 'How much does a 25 mph over speeding limit ticket cost?', preview: <div>
    <div className="BodyGreyLeft mb16">If you just pay the ticket, the fine will likely be at least $150, plus court cost between $25-$75. But your insurance will also go up hundreds, even thousands, of dollars each year for 3-5 years because of the points you will get on your record. TicketTamer charges just $325 for a speeding ticket 25-29 miles over the speed limit – and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you just pay the ticket, the fine will likely be at least $150, plus court cost between $25-$75.
    {/* <br/><br/>
    <a href="https://www.courts.mo.gov/page.jsp?id=310" className="Link" target="_blank" rel="noreferrer">Click here for state and local court fine information. <ArrowRightOutlined /></a> */}
    <br/><br/>
    But you will also have to pay hundreds, even thousands, more for your insurance because that goes up when you get a ticket. The actual cost will vary depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court.
    {/* <br/><br/>
    <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer"> Click here for more information on tickets and insurance. <ArrowRightOutlined /></a> */}
    <br/><br/>
    Just paying your ticket is the most expensive way to handle your ticket.
    <br/><br/>
    <strong>TicketTamer can keep your insurance rates down and your record clean by fixing your ticket. We will negotiate with the prosecutor to reduce the ticket to a non-moving or no point violation so your record will stay clean and your insurance rates won’t go up.</strong>
    <br/><br/>
    TicketTamer charges just $275 for a speeding ticket of up to 20-24 miles per hour over the speed limit: the $75 basic ticket fee we charge for all tickets, and a $250 surcharge for the higher speed. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.

    </div> 
  </div>},
  {key: 12, title: 'How much does a 30 mph over speeding limit ticket cost?', preview: <div>
    <div className="BodyGreyLeft mb16">There is no set fine for speeding 30 mph over the speed limit. At this rate of speed, prosecutors generally want jail time. Your insurance will also go up hundreds, even thousands, of dollars each year for 3-5 years because of the points you will get on your record. TicketTamer charges just $500 for a speeding ticket 30+ miles over the speed limit – and you won’t have to go to court.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    There is no set fine for speeding 30 mph over the speed limit. At this rate of speed, prosecutors generally want jail time.
    <br/><br/>
    {/* <a href="https://www.courts.mo.gov/page.jsp?id=310" className="Link" target="_blank" rel="noreferrer">Click here for state and local court fine information. <ArrowRightOutlined /></a>
    <br/><br/> */}
    Your TicketTamer lawyer can help you avoid this by negotiating a different penalty, such as community service, driver improvement class, and a fine. TicketTamer charges just $500 for a speeding ticket of up to 30 + miles per hour over the speed limit, the $75 basic ticket fee we charge for all tickets, and a $425 surcharge for the higher speed. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.
    
    </div> 
  </div>},
  {key: 13, title: 'How do I dismiss a speeding ticket in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">You can go to court and ask the judge to dismiss it. He almost certainly won’t because traffic tickets are often just about the money, and the judge is thereto make sure you pay, not let you off the hook. <strong>TicketTamer can fix your ticket for you for just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit. – and you won’t have to go to court.</strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    You can go to court and ask the judge to dismiss it. He almost certainly won’t because traffic tickets are often just about the money, and the judge is thereto make sure you pay, not let you off the hook.
    <br/><br/>
    The best way is to hire TicketTamer to fix it for you, without your having to go to court. While the ticket won’t be “dismissed,” we will negotiate with the prosecutor to reduce it to a non-moving or no point violation so your record will stay clean and your insurance rates won’t go up.
    <br/><br/>
    TicketTamer charges just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit. <Link to={Routes.PRICING}>Other charges</Link> apply depending upon how fast you were going, the court the ticket is in, and other factors.
   
    </div> 
  </div>},
  {key: 14, title: 'How many points does a speeding ticket give you in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">Most speeding tickets are between 2-4 points in Missouri.They stay on your record for 3 years. Insurance companies use these points to increase your insurance rates by hundreds, even thousands, of dollars a year for 3-5 years. <strong> TicketTamer can fix your ticket for you for just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit. – and you won’t have to go to court.</strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Most speeding tickets are between 2-4 points in Missouri.
    <br/><br/>
    They stay on your record for 3 years. Insurance companies use these points to increase your insurance rates by hundreds, even thousands, of dollars a year for 3-5 years.
    <br/><br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri’s point system.<ArrowRightOutlined /></a>
    <br/><br/> */}
    <strong>TicketTamer can keep your insurance rates down and record clean by negotiating with the prosecutor to reduce your ticket to a non-moving or no-point violation.</strong>
    <br/><br/>
    <i><strong>This is the most expensive way to handle your ticket.</strong></i>
    <br/><br/>
    TicketTamer charges just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.

    </div> 
  </div>},
  {key: 15, title: 'What happens if you are caught speeding 40 mph in a 30 mph zone?', preview: <div>
    <div className="BodyGreyLeft mb16">You’ll probably get a speeding ticket if you get caught speeding 40 mph in a 30-mph zone.This ticket will generally add 2-4 points to your record, and if you just plead guilty and pay the fine, it will cost about $55 plus court costs. <strong>TicketTamer can fix your ticket for you for just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit. – and you won’t have to go to court.</strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    You’ll probably get a speeding ticket if you get caught speeding 40 mph in a 30-mph zone.
    <br/><br/>
    This ticket will generally add 2-4 points to your record, and if you just plead guilty and pay the fine, it will cost about $55 plus court costs.
    {/* <br/><br/>
    <a href="https://www.courts.mo.gov/page.jsp?id=310" className="Link" target="_blank" rel="noreferrer">Click here for state and local court fine information. <ArrowRightOutlined /></a> */}
    <br/><br/>
    You’ll also have to pay more for insurance because insurance companies use traffic tickets to increase their rates. The actual cost will vary depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court.
    {/* <br/><br/>
    <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer"> Click here for more information on tickets and insurance. <ArrowRightOutlined /></a> */}
    <br/><br/>
    This is the most expensive way to handle your ticket.<strong>TicketTamer can keep your insurance rates down by fixing your ticket. We will negotiate with the prosecutor to reduce the ticket to a non-moving or no point violation so your record will stay clean and your insurance rates won’t go up.</strong>
    <br/><br/>
    TicketTamer charges just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.

    </div> 
  </div>},
  {key: 16, title: 'How long does a speeding ticket stay on your record in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">Your speeding ticket will stay on your record forever. The points for the ticket will stay on your record for 3-5 years. <strong>TicketTamer can fix your ticket for you for just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit. – and you won’t have to go to court.</strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Your speeding ticket will stay on your record forever.
    {/* <br/><br/>
    <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri’s point system.<ArrowRightOutlined /></a> */}
    <br/><br/>
    The ticket will put between 2-4 points on your record, and they will stay on your driving record for 3 years.
    <br/><br/>
    Even when the points fall off of your record, the speeding violation will stay on your record forever. Because the violation is still on your record, insurance companies will charge you higher rates for 3-5 years, depending upon the insurer and the state.
    {/* <br/><br/>
    <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer"> Click here for more information on tickets and insurance. <ArrowRightOutlined /></a> */}
    <br/><br/>
    <strong>TicketTamer can keep your record clean and insurance rates down by negotiating with the prosecutor to reduce your ticket to a non-moving or no-point violation.</strong>
    <br/><br/>
    TicketTamer charges just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.

    </div> 
  </div>},
]

const SpeedingTicketLawyers = (props) => {
  return <ServiceTemplateCascade 
  title="Speeding Ticket Lawyers" 
  items={Items}
  image={speedingImg}
  url="submit-speeding-ticket"
  metaDescription="Submit your speeding ticket  - Get your quote instantly - Schedule your free consultation"
  />;
}

export default SpeedingTicketLawyers;
